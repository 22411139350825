
.cashier_main_container_spinner{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background: url(../../Spinner/img/green_bg.jpg);  */
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    font-family: sura-regular;
}
.cashier_numbers {
    
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex;
    justify-content: flex-start;
    padding-left: 10px;
    padding-top: 10px;
    /* position:relative; */
}
.cashier_neighbor_div{
    position: absolute;
    z-index: 100;
    background-color: rgba(0,0,0,0.6);
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cashier_neighbor_numbers{

    
    text-align: center;
    background-color: rgba(0,0,0,0.9);
    padding: 20px 30px;
    border: 3px solid rgb(155, 60, 91);
}
.cashier_neighbor_tbl td{
        width: 50px;
        height: 50px;
        text-align: center;
        color: #fff;
        
       
}

.cashier_neighbor_close{
    color: #fff;
    cursor: pointer;
    margin-bottom: 10px;
}
.cashier_game_number, .cashier_timer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0px!important;
    color: #fff;
    
    height: 25px;
}
.cashier_game_numberr, .cashier_timer {
    display: flex;
    font-size: 28px;
    justify-content: left;
    align-items: center;
    padding-bottom: 0px !important;
    color: #fff;
    height: 25px;
}

.cashier_input_fields{
  
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10px;
    padding-top: 10px;
}
.cashier_keyboard{
    
    height: 100vh;
   
     padding-right: 10px;
     padding-left: 10px;
    padding-top: 10px; 
}

.cashier_progress-bar-horizontal {
    height: 10px;
    /* min-height: 400px; */
    margin-right: 10px!important;
    margin-left: 10px;
    /* background: #d0cece; */
     background: #798078!important; 
    /* background-image: linear-gradient(180deg, #444943, #808A7E); */
    display: -webkit-box;  
    display: -ms-flexbox;  
    display: -webkit-flex; 
    display: flex;        
    align-items: flex-start;
    -webkit-align-items: flex-start;
  }
  
  .cashier_progress-bar-horizontal .cashier_progress-bar {
    width: 0;
    height: 100%;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
     /* background-color: #469C30;  */
     background-color: #0000CD; 
     border-radius: 5px; 
    
  }
  .cashier_progress_timer_container{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
  }
  .cashier_progress-bar-horizontal{
       border-radius: 5px; 
  }

  .cashier_timer_logout_container{
      /* background: rgba(255, 255, 255, 0.2);*/
    background: black;
      padding: 10px 15px;
      border-radius: 5px;
  }
  .cashier_progress_container{
      display: flex;
      align-items: center;
      justify-content: center;
     
      height: 25px;
  }
    
  .cashier_spn_verify, .cashier_spn_cancel, .cashier_spn_tm {
      color: #fff;
      border: 1px solid #fff;
      border-radius: 10px;
      margin-left: 10px;
      font-size: 18px;
      font-weight: bolder;
      background-color: rgb(255, 230, 0);
      padding: 2px 8px;
  }
  .cashier_spn_cancel {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    margin-left: 10px;
    font-size: 18px;
    font-weight: bolder;
    background-color: orange;
    padding: 2px 8px;
}
.cashier_spn_tm {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    margin-left: 10px;
    font-size: 18px;
    font-weight: bolder;
    background-color: #0bab64;
    padding: 2px 8px;
}
  .cashier_spn_signout{
        color: orange;
        border: 1px solid #fff;
        border-radius: 10px;
        font-size: 18px;
        font-weight: bolder;
        padding: 2px 8px;
  }
 
  .cashier_bet_keys{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      /* padding: 10px 15px; */
      margin-top: 10px;
  }

  .cashier_high_low{
        
        width: 25%;
        padding-right: 10px;
  }
  .cashier_number_keys{

width: 50%;

  }
  .cashier_mirrors{

padding-left: 10px;
width: 25%;

  }
  .cashier_tbl_high_low, .cashier_tbl_high_color, .cashier_tbl_mirrors, .cashier_tbl_sectors, .cashier_tbl_finals{
      width: 100%;
      border-spacing: 3px;
    border-collapse: collapse;
  }
  
  
  .cashier_tbl_high_low td {
     width: 33.3px;
        height: 60px;
        text-align: center;
        
        
        /* background-image: linear-gradient(red, yellow); */
        background-image: linear-gradient(#fff, #d3d3d3);
  }
  .cashier_tbl_high_color td{
      width: 50%;
      height: 60px;
      text-align: center;
      padding: 5px 7px;
  }
  .cashier_tbl_mirrors td.cashier_td_mirrors{
      width: 50%;
      height: 60px;
      text-align: center;
      padding: 5px 7px;
      /* background: #2058c9; */
      background: #0000CD;
      color: #fff;
  }
  .cashier_tbl_sectors td{
    width: 33.3%;
    height: 45px;
    text-align: center;
    /* padding: 5px 7px; */
    background: rgb(155, 60, 91);
    color: #fff;
}

.cashier_tbl_finals td{
    width: 33.3%;
    height: 45px;
    text-align: center;
    /* padding: 5px 7px; */
     background: #0000CD;
    color: #fff;
}
  .cashier_tbl_twins_random , .cashier_tbl_donzens{
    width: 129%;  
    margin-left: 90px;
    border-spacing: 3px;
    border-collapse: separate;
    margin-top: 20px;
  }
  .cashier_tbl_twins_random td, .cashier_tbl_donzens td{
       width: 33.3%;
       height: 45px;
       text-align: center;
       font-size: 24px;
        background: #0000CD;
        color: #fff;
  }

.cashier_tbl_numbers_only_spinner {
    width: 150%;
    border-spacing: 1px;
    border-collapse: collapse;
    border: 2px solid #fff;
}

.cashier_tbl_numbers_only_spinner_sector {
    width: 114%;
    margin-left: 43px;
    text-align: center;
    font-size: 18px;
    border-spacing: 3px solid;
    border-collapse: collapse;
    height: 50px;
    padding-top: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.cashier_tbl_numbers_only_spinner_sector td {
    font-size: 22px;
    text-align: center;
    height: 50px;
    margin-top: 20px;
}
.cashier_tbl_numbers_only_spinner12 {
    width: 114%;
    margin-left: 43px;
    text-align: center;
    font-size: 18px;
    border-spacing: 3px solid;
    border-collapse: collapse;
    border: 2px solid #fff;
    height: 50px;
    margin-top: "20px";
}
.cashier_tbl_numbers_only_spinner12 td{
     border-spacing: 3px solid;
    border-collapse: collapse;
    border: 2px solid #fff;
    height: 50px;
    text-align: center;
    font-size: 22px;
    color: white;
}
.cashier_tbl_numbers_only_sector {
    width: 120%;
    margin-left: 20px;
    border-spacing: 3px;
    border-collapse: collapse;
    border: 2px solid #fff;
}
.cashier_tbl_numbers_only_spinner td {
        /* baze width: 16.6%;*/
        height: 55px;
        width: 50px;
        font-size: 26px;
        text-align: center;
        border-collapse: collapse;
        border: 3px solid #fff;
        background: #000;
        color: #fff;
    }
    .cashier_tbl_numbers_onlly td {
        /* baze width: 16.6%;*/
        height: 55px;
        font-size: 19.7px;
        text-align: center;
        border-collapse: collapse;
        border: 3px solid #fff;
        background: #000;
        color: #fff;
    }
.cashier_tbl_numbers_onllly td {
    /* baze width: 16.6%;*/
    height: 55px;
    font-size: 26px;
    text-align: center;
    border-collapse: collapse;
    border: 3px solid #fff;
    background: #000;
    color: #fff;
}
@media screen and (max-width: 1365px) 
{
    .cashier_progress-bar-horizontal{
        width: 140px;
    }
    .cashier_main_container_spinner{
        width: 1400px; 
       /* width: 100%; */
       
    }
   .cashier_numbers {
    /* width: 700px !important;  */
    width: 50%;
  
}

.cashier_input_fields {
     /* width: 350px !important;  */
    width: 25%;
   
 }

 .cashier_keyboard {
    /* width: 350px !important; */
   width: 25%;
 }

}
@media screen  and (min-width: 1365px)
{
    .cashier_progress-bar-horizontal{
        width: 160px;
    }
    .cashier_main_container_spinner{
        width: 100%;
    }
    .cashier_numbers {
        width: 50% !important;
       
     }
     
     .cashier_input_fields {
         width: 25% !important;
         
        
      }
     
      .cashier_keyboard {
         width: 25% !important;
        
      }

}
.cashier_btn_clear{
    width: 100%!important;
    height: 40px;
    background-image: linear-gradient(#0bab64, #3bb78f) ;
    color: #fff;
    border: 1px solid green;
    border-radius: 2px;
    margin-bottom: 30px;
    margin-top: 60px;
}
.cashier_btn_custom_amount{
    width: 45%;
    height: 40px;
    background-image: linear-gradient(#0bab64, #3bb78f) ;
    color: #fff;
    border: 1px solid green;
    border-radius: 2px;
    
}
.cashier_custom_amount{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.cashier_input_print{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
}
.cashier_input_print input[type = "number"]{
    width: 65%;
    height: 40px;
    padding: 3px 5px;
    border-radius: 10px;
}
.cashier_input_print .cashier_btn_print{
    width: 30%;
    height: 40px;
    padding: 3px 5px;
    border-radius: 10px;
    background: lightgreen;
    
}
.cashier_keyboard_numbers{
    margin-top: 10px;

}
.cashier_tbl_keyboard_numbers {
    width: 100%;
    border-spacing: 4px;
    border-collapse: separate;
}
.cashier_tbl_keyboard_numbers td  {
    width: 33%;
    height: 45px;
    text-align: center;
    padding: 5px 7px;
 
    /* background-image: linear-gradient(red, yellow); */
    background-image: linear-gradient(#fff, #d3d3d3);
}
.cashier_main_container_spinner td:hover , .cashier_main_container_spinner button:hover{
  cursor: pointer;
   color: black!important;
  background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%)!important;
   animation:slidebg 5s linear infinite; 
 
  
  transition: .3s;
}
.cashier_spn_verify:hover, .cashier_spn_tm:hover, .cashier_spn_cancel:hover, .cashier_spn_signout:hover{
   cursor: pointer;
}
.cashier_inputs {
    height: 50px;
    background-image: linear-gradient(#fff, #d3d3d3);
    display: flex;
   }
.cashier_inputs input{
    height: 30px;
    width: 100%;
    border-radius: 5px;
    padding: 2px 5px;
    
}



.custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table .header-cell,
  .custom-table .data-cell {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .custom-table .header-cell {
    background-color: #f2f2f2;
  }
  
  .custom-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .custom-table .header-cell {
    text-align: left;
  }
  
  .custom-table .data-cell {
    text-align: right;
  }

  
.tab_container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .tabs {
    display: flex;
    justify-content: flex-start;
  }
  
  .tab {
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .tab.active {
    background-color: lightblue;
  }
  
  .content {
    margin-top: 20px;
  }

  
.cashier_verify input{
    height: 30px;
    width: 100%;
    border-radius: 5px;
    padding: 2px 5px;
}
.cashier_tbl_verify th{
    background: #666;
    height: 40px;
    color: #fff;
    border: 1px solid #999;
}
.cashier_tbl_verify td{
    height: 40px;
    color: #fff;
    border: 1px solid lightcoral;
}
.cashier_btn_remove{

    height: 30px;
    width: 30px;
    border: 0px solid black;
    background: red;
    border-radius: 50%;
}
.cashier_tbl_tm {
    width: 100%;
    font-size: 18px;
}
.cashier_tbl_tm .th_left {
    text-align: center;
    width: 60%;
    height: 50px;
 
}



.cashier_tbl_tm .th_right {
    text-align: center;
    width: 40%;
    height: 50px;
   
}

@keyframes slidebg {
    to {
      background-position:20vw;
    }
  }
  
  .follow{
    margin-top: 40px;
  }
  
  .follow a{
    color: black;
    padding: 8px 16px;
    text-decoration: none;
  }
/* @media screen and (max-width: 2000px) and (min-width: 1500px)
{
   .dv_spin {
   width: 700px !important;
   height: 700px !important;
   border-radius: 50%;
}

}
@media screen and (max-width: 3000px) and (min-width: 2000px)
{
   .dv_spin {
   width: 860px !important;
   height: 860px !important;
   border-radius: 50%;
}

} */

.ticket td,
.ticket th,
.ticket tr,
.ticket table {
    /* border-top: 1px solid black; */
    border-collapse: collapse;
    font-size: 10px;
    font-family: 'Times New Roman', Times, serif;
}

td.description,
th.description {
    width: 75px;
    max-width: 75px;
}

td.quantity,
th.quantity {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

td.price,
th.price {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

.centered {
    text-align: center;
    align-content: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 10px;
}

.ticket {
    width: 170px;
    max-width: 170px;
    margin-top: 20px;
    margin-right: 5px;
    margin-left: 5px;
}

.ticket img {
    max-width: inherit;
    width: inherit;
}

@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}
cashier_donzens-spinner {
    margin-top: 20px;
    margin-bottom: 20px;
}
cashier_donzens {
    width: 114%;
    margin-left: 43px;
    text-align: center;
    font-size: 18px;
    border-spacing: 3px solid;
    border-collapse: collapse;
    border: 2px solid #fff;
    height: 50px;
    padding-top: 20px;
}
.cashier_numbers_only td:hover::before, .cashier_numbers_only td:hover::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 80%;
  background-color: white;
}
.cashier_numbers_only td:hover::before {
  position: absolute;
  border-radius: 3px solid white;
  margin-left: -34px;
  margin-top: -13px;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Shadow effect on hover */
  }
.cashier_numbers_only td:hover::after {
  position: absolute;
  border-radius: 3px solid white;
  margin-left: 27px;
  margin-top: -15px;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Shadow effect on hover */
  }
.cashier_numbers_only td:hover + .cashier_numbers_only td::before, .cashier_numbers_only td:hover ~ .cashier_numbers_only td::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: white;
  top: 0;
  left: 0;
}