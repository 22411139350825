@font-face {
  font-family: roboto-regular;
  src: url("./fonts/roboto/Roboto-Regular.ttf");
  /* src: url('./fonts/Sura/Sura-Regular.ttf');  */
}

body {
  /* font-family: "Open Sans", sans-serif; */
  /* background: url(../img/green_bg.jpg) no-repeat center center fixed; */
  background: rgb(247, 40, 26);
  background: linear-gradient(113deg,  #990A02 1%, #B50900 10%,rgb(253, 21, 4) 70%, rgb(247, 40, 26) 90%,rgba(45,0,1,1) 100%);
  /* background: #d2042d; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* font-weight: 600;  */
}
